<template>
  <div>
    <template>
      <!-- First Row -->
      <b-card>
        <b-row>
          <h4 class="pl-1 pb-1">Numero de apuesta: {{$route.params.id}}</h4>
          <b-table
            md="9"
            :items="dataItems"
            :fields="fields"
            class="scroll"
            responsive
          >
            <template #cell(bet)="data">
              <b-button
                variant="outline-primary"
                >{{ data.item.bet }}</b-button
              >
            </template>
            <template #cell(status)="data">
              <span v-if="data.item.status === 'CANCELED'" >
                CONFIRMED
              </span>
              <span v-else >PENDING</span>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount.REAL | currency({ symbol: "" }) }}
            </template>
            <template #cell(final_amount)="data">
              {{ data.item.final_amount.REAL | currency({ symbol: "" }) }}
            </template>
          </b-table>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <h3 class="pl-1 pb-1">{{ $t("labels.ticketdetail") }}</h3>
          <b-table
            md="9"
            :items="dataSelelections"
            :fields="detailFields"
            class="scroll"
            responsive
          >
            <template #cell(img)="data">
              <img :src="data.item.img" width="50%">
            </template>
          </b-table>
        </b-row>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            to="/apps/reports/sports/betsPlacedTmp"
          >
            {{ $t("buttons.back") }}
          </b-button>&nbsp;&nbsp;
          <span v-if="rows.status === 'CANCELED'" >
              <b-button
                variant="outline-success"
                @click="printTicket( rows.bet )"
                >{{ $t("buttons.print") }}</b-button
              >
          </span>
          &nbsp;&nbsp;
          <span v-if="( (typeUser === 'Agent') && (rows.status == 'CANCELED') )" hidden >
            <b-button variant="warning" v-b-modal.modal-1 >
              Imprimir ticket
            </b-button>
          </span>
          <span v-if="( (typeUser === 'Agent') && (rows.status == 'PENDING') )" >
            <span v-if="confirm.allowConfirm == true" >
              <span v-if="(confirm.allowConfirm === true && confirm.allStarted === true)" >
                  <b-button variant="warning">
                    No se puede confirmar ticket, todos los eventos iniciados
                  </b-button>
              </span>
              <span v-else-if="confirm.eventStarted === true" >
                <b-button variant="warning" @click="confirmTicket( rows.bet, rows.amount.REAL )" :disabled="cancelTicketButton">
                  Confirmar ticket con eventos inicializados
                </b-button>
              </span>
              <span v-else-if="confirm.eventStarted === false" >
                <b-button variant="success" @click="confirmTicket( rows.bet, rows.amount.REAL )" :disabled="cancelTicketButton">
                  Confirmar ticket
                </b-button>            
              </span>
            </span>
          </span>
          &nbsp;&nbsp;
          <!-- Boton para cancelar -->
          <!--<span v-if="( (typeUser === 'Agent') && (rows.status === 'PENDING') && (confirm.eventStarted === false  ) && ( this.diffDate <=10 ) )" >
            <b-button variant="danger" @click="cancelTicket( rows.bet )" :disabled="cancelTicketButton">
                  Cancelar
            </b-button>
          </span>
          <span v-if="( (typeUser === 'Root' || typeUser === 'Admin' ) )" >
            <span v-if="( rows.status === 'PENDING' )">
            <b-button variant="danger" @click="cancelTicket( rows.bet )" :disabled="cancelTicketButton">
                    Cancelar
              </b-button>
            </span>
          </span>-->
          <b-modal id="modal-1" title="Vista de impresion">
                <p class="my-4">
                    <b-embed
                      centered
                      type="iframe"
                      aspect="16by9"
                      :src="`https://web.sportsbook.dev.whatsbet.net/${this.renderPreview}/ticket/${this.ticket}`"
                      allowfullscreen
                    ></b-embed>
                </p>
              </b-modal>
          </div>
        <!--<div class="d-flex mt-2">
          <span v-if="( (typeUser === 'Agent') && (rows.status == 'PENDING') && (confirm.eventStarted === false  ) && ( this.diffDate <=10 ) )" >
            <div style="color:red;font-weight: bold;" >Han pasado {{this.diffDate}} minutos desde que se ha generado el ticket</div>
          </span>
        </div>-->
      </b-card>
      <!-- Form Actions -->
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import betsPlacedStoreModule from "./betsPlacedStoreModule";

export default {
  template: "#modal-template",
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
  },
  data() {
    return {
      fields: [
        { label: "Origen", key: "source" },
        { label: "Ticket Id", key: "bet" },
        { label: "Usuario", key: "username" },
        { label: "Fecha", key: "created" },
        { label: "Status", key: "status" },
        { label: "Monto Apostado", key: "amount" },
        { label: "Monto a Cobrar", key: "final_amount" },
      ],
      detailFields: [
        { label: "Tipo", key: "type" },
        { label: "Punto", key: "punto" },
        { label: "Valor", key: "valor" },
        { label: "Periodo", key: "periodo" },
        { label: "Status", key: "status" },
        { label: "Juego", key: "juego" },
        { label: "", key: "img" }
      ],
      rows: [],
      confirm: [],
      dataItems: [],
      dataTicket: [],
      tableElement: [],
      dataSelelections: [],
      buttonTicket: false,
      renderPreview: '',
      ticket: '',
    };
  },
  computed:{
    cancelTicketButton() { 
      return this.buttonTicket
    }
  },
  created() {
    this.idUserSelect = JSON.parse(localStorage.getItem("userData"))._id;
    this.walletUserId = JSON.parse(localStorage.getItem("userData")).walletUserId;
    this.userNameDestination = JSON.parse(localStorage.getItem("userData")).userName
    this.typeUser = JSON.parse(localStorage.getItem("userData")).typeUser;
    const SPORT_APP_STORE_MODULE_NAME = "app-sport";
    if (!store.hasModule(SPORT_APP_STORE_MODULE_NAME))
      store.registerModule(SPORT_APP_STORE_MODULE_NAME, betsPlacedStoreModule);
    onUnmounted(() => {
      if (store.hasModule(SPORT_APP_STORE_MODULE_NAME))
        store.unregisterModule(SPORT_APP_STORE_MODULE_NAME);
    });

  },
    methods:{
      getSessionUser( ticket ){
        
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const walletUserId = this.walletUserId

        const params = {
        "userId": walletUserId
        };
        const result = axios
        .post(`${VUE_APP_URL}/getSessionUser`, params)
        .then((r) => {
          this.ticket = ticket
          this.renderPreview = r.data.idSession
          const URL = `https://web.sportsbook.dev.whatsbet.net/${this.renderPreview}/ticket/${this.ticket}`
          window.open(URL, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
          
        }
        ).catch((error) => console.log(error.response));
        return result
      },
      confirmTicket( ticketId, amount_real ) {

        const VUE_APP_URL = process.env.VUE_APP_URL;
        const walletUserId = this.walletUserId
        const currency = this.$store.state.whitelabelCurrencyNabvar.currency;

        const params = {
        "currency": currency,
        "amount": amount_real,
        "userId": this.idUserSelect,
        "user_id": walletUserId,
        "ticket": ticketId
      };
      const result = axios
        .post(`${VUE_APP_URL}/confirmDetail`, params)
        .then((r) => {
            this.$toast({
            component: ToastificationContent,
            props: {
              title: r.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });

          setTimeout(function(){
              window.location.reload()
          }, 2000);

          this.buttonTicket = true

        }
        )
        .catch((error) => console.log(error.response));
      return result;

      },
      cancelTicket( ticket ) {
        const username = this.userNameDestination
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const currency = this.$store.state.whitelabelCurrencyNabvar.currency;

        const params = {
          "ticketId": ticket,
          "currency": currency,
          "username": username,
          whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id
        };
      const result = axios
        .post(`${VUE_APP_URL}/cancelTicket`, params)
        .then((r) => {
            if( r.status === 200 ){
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: r.data.message,
                  icon: "EditIcon",
                  variant: "success",
                },
              });

              setTimeout(function(){
                  window.location.reload()
              }, 2000);

              this.buttonTicket = true
            }
        }
        )
        .catch((error) => console.log(error.response));
      return result;

      },
      printTicket( ticket ){
        this.getSessionUser( ticket )
      },
      diffDate( createdTicket ){
        const endTicket = moment( createdTicket ).format("YYYY/MM/DD")
        const now = moment(new Date()); // fecha de hoy 
        const end = moment( endTicket ); // fecha del ticket
        const duration = moment.duration(now.diff(end));
        return duration.minutes()
      }
    },
    mounted(){
      const VUE_APP_URL = process.env.VUE_APP_URL
      const ticketId = router.currentRoute.params.id
      const currency = this.$store.state.whitelabelCurrencyNabvar.currency;
      const params = {
        "ticketId": ticketId,
        "currency": currency,
        "userId": this.walletUserId,
        whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
      };
      const result = axios
        .post(`${VUE_APP_URL}/showTicketsDetail`, params)
        .then(( r ) => {
          this.rows = r.data.row.ticket
          this.confirm = r.data.o
          this.dataItems.push(r.data.row.ticket)
        let ticketDetail = r.data.row.ticket.selections
        let detailFiltered = []
        ticketDetail.map(function ($itemDetail) {
          Object.keys($itemDetail).map(function ($detailIndex) {
            detailFiltered.push($itemDetail[$detailIndex])
          })
        })
        detailFiltered.forEach(e => 
          this.dataSelelections.push({
            type: e.outcome.name,
            punto: e.select.text,
            valor: e.priceAmerican,
            periodo: e.select.time,
            status: e.event.status == 'NSY' ? 'No iniciado' 
            : e.event.status == 'inprogress' ? 'En progreso' 
            : e.event.status == 'manual' ? 'Manual'
            : e.event.status == 'Finished' ? 'Finalizado'
            : e.event.status == 'Graded' ? 'Cerrado'
            : e.event.status == 'grade_bets' ? 'Por Cerrar Jugadas'
            : e.event.status == 'Abandoned' ? 'Abandonado'
            : e.event.status == 'Cancelled' ? 'Cancelado'
            : e.event.status == 'Interrupted' ? 'Interrumpido'
            : e.event.status == 'Postponed' ? 'Pospuesto' : '',
            juego: e.event.name,
            img: `https://s3.amazonaws.com/sportsbookplay/assets/icon/sport/200x200/${e.event.sport.id}.png`
          })
        );
        const createdTicket = this.rows.created
        // Diferencias entre fechas para sacar el tiempo transcurrido
        this.diffDate = this.diffDate( createdTicket )
        }
        )
        .catch((error) => console.log(error.response));
      return result;
    }
};
</script>

<style>
</style>